.OnboardingContainer {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 15vw;
  margin-right: 15vw;
}

.OnboardingContent {
  text-align: center;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 5vh;
}

@media only screen and (max-width: 600px) {
  .OnboardingContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 5vw;
    margin-right: 5vw;
  }
  .OnboardingContent {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    margin-top: 5vh;
  }
}

.OnboardingPlanCardsContainer {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.OnboardingPlansPanel {
  background: #f7f7f7;
  border-radius: 8px;
  margin-bottom: 24px;
  border: none;
  border-bottom: none;
  overflow: hidden;
  text-align: left;
}

.OnboardingPlansPanelSelected {
  background: #ffffff;
  border-width: 25px;
  border-radius: 8px;
  margin-bottom: 24px;
  border: #4abdac;
  overflow: hidden;
  text-align: left;
}

.OnboardingContentSubText {
  font-size: 16px;
}

.OnboardingContentdetailText {
  font-size: 12px;
  margin-top: 10px;
}

.OnboardingContentInput {
  margin-top: 35px;
}

.OnboardingContentButtons {
  margin-top: 35px;
  text-align: center;
  margin-bottom: 15px;
}

.stepsButton {
  margin-top: 35px;
  text-align: center;
  margin-bottom: 10px;
}

.flagImg {
  width: 1.6em;
  height: 1.6em;
  margin-right: 0.2em;
  top: 0.3em;
}
