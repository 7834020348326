
.BusinessInviteFormContent {
  text-align: center;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 5vh;
}

.BusinessInviteFormContentSubText {
  font-size: 16px;
}

.BusinessInviteFormContentButtons {
  margin-top: 35px;
  text-align: center;
  margin-bottom: 15px;
}


.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.emailAddressFormInput {
  width: 60%;
  margin-right: 8px;
}

.addInviteButton {
  width: 60%;
  margin-right: 30px;
}

@media only screen and (max-width: 600px) {
  .emailAddressFormInput {
    width: 90%;
    margin-right: 8px;
  }

  .addInviteButton {
    width: 90%;
    margin-right: 30px;
  }

  .BusinessInviteFormContent {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    margin-top: 5vh;
  }
}


