.teamInviteContainer {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  margin-left: 35vw;
  margin-right: 35vw;
  padding-top: 5vh;
}

@media (max-width: 600px) {
  .teamInviteContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 75vh;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

.teamInviteAnimationMessage {
  text-align: center;
}

.teamInviteFormAnimation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
}
