.BusinessAddressFormContent {
  text-align: center;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 5vh;
}

@media only screen and (max-width: 600px) {
  .BusinessAddressFormContent {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    margin-top: 5vh;
  }
}

.BusinessAddressFormContentSubText {
  font-size: 16px;
}

.BusinessAddressFormContentInput {
  margin-top: 35px;
}

.BusinessAddressFormContentButtons {
  margin-top: 35px;
  text-align: center;
  margin-bottom: 15px;
}

.ant-advanced-search-form {
  margin-top: 35px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.continueStepButton {
  margin-bottom: 25px;
}
