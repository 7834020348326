.BusinessNameFormContent {
  text-align: center;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 5vh;
}

@media only screen and (max-width: 600px) {
  .BusinessNameFormContent {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    margin-top: 5vh;
  }
}

.BusinessNameFormContentSubText {
  font-size: 16px;
}

.BusinessNameFormContentInput {
  margin-top: 35px;
}

.BusinessNameFormContentButtons {
  margin-top: 35px;
  text-align: center;
  margin-bottom: 15px;
}
